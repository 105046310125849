import * as React from "react";
import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import "./Layout.scss";

// eslint-disable-next-line react/prop-types
const Layout = ({ children }) => {
  return (
    <div className="layout-container">
      <Helmet htmlAttributes={{ lang: "es" }}>
        <meta charSet="utf-8" />
        <title>
          Qavant – Una plataforma de comunicación para estar más cerca
        </title>
        <meta
          name="description"
          content="Qavant es una plataforma pensada para simplificar la comunicación, los procesos y la gestión de tus colaboradores de una manera sencilla y eficiente."
        />
      </Helmet>
      <NavBar />
      <main>{children}</main>
      {location.pathname === "/news" ? "" : <Footer />}
    </div>
  );
};
export default Layout;
